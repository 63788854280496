import React, { useEffect, useState } from "react"
import { navigate } from "@reach/router"
import { useAuth0 } from "@auth0/auth0-react"
import { client } from "../../sanity"
import { Flex, Title, Box, Text, Button } from "../../components/system"
import theme from "../../components/theme"

const Manage = ({ location }) => {
  const { user } = useAuth0()
  const [info, setInfo] = useState({})
  const [submitting, setSubmitting] = useState(false)
  // Get Subscription ID from Sanity
  // Check Stripe for Subscription Details (Product Title, Price, Billing Cycle, Due Next etc)
  // Display Subscription Details
  // Option to cancel/upgrade subscription

  useEffect(() => {
    const role = user["https://ilana.uk/roles"][0]
    const query = `*[_type == ${
      role === "Expert" ? '"expert"' : '"user"'
    } && uuid == $user]`
    const params = { user: user.sub }

    client
      .fetch(query, params)
      .then(user => {
        setInfo(user[0])
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const cancelSubscription = async () => {
    setSubmitting(true)
    await fetch("/.netlify/functions/cancel", {
      method: "post",
      body: JSON.stringify({
        stripeId: info.stripe,
      }),
    }).then(() => {
      setSubmitting(false)
      navigate("/community")
    })
  }

  return (
    <Box>
      <Flex flexDirection="column" py={[4]}>
        <Title
          fontSize={[6, 7]}
          color={theme.colors.secondary}
          textTransform="capitalize"
        >
          Manage Subscription
        </Title>
        <Text maxWidth={600} px={3}>
          If you have any questions about your subscription, please contact us
          community@ilana.uk
        </Text>
      </Flex>

      <Flex mb={5}>
        <Button
          bg="black"
          color="white"
          hbg="#2d2d2d"
          hcolor="white"
          onClick={() => cancelSubscription()}
          disabled={submitting}
        >
          {submitting ? "Please Wait..." : "Cancel Subscription"}
        </Button>
      </Flex>
    </Box>
  )
}

export default Manage
